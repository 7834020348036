// *****************************************************************************
   * Inbox mixins
   *****************************************************************************

@use "sass:list"
@use "sass:math"

=bgi($image)
  background-image: url($image)
=bgc($color)
  background-color: $color
=bgr($repeat: no-repeat)
  background-repeat: $repeat
=bgp($position: 0 0)
  background-position: $position

=db
  display: block
=dn
  display: none
=di
  display: inline
=dib
  display: inline-block
=dt
  display: table
=dtc
  display: table-cell
=dtr
  display: table-row
=fl
  float: left
=fr
  float: right
=h($H: auto)
  height: $H

// Inbox input field
  $borco - border color
  $h   - input height
  $br  - border radius
=ii-input($borco: #bbb, $h: 1.875 * $font-size-base, $bgco: white, $br: $border-radius-base - 2)
  background-color: $bgco
  border: 1px solid $borco
  +h($h)
  //+lh($line-height-base)
  margin: 0
  padding: calc($grid-min-size - 1px) 4px
  box-sizing: border-box
  border-radius: $br

=lh($H: $base-line-height)
  line-height: $H
=oh
  overflow: hidden
=nmp
  margin: 0
  padding: 0
=pos($p: static)
  position: $p
=poa
  position: absolute
=por
  position: relative
//=set-default-font($size: $font-size-base, $line: $line-height-base, $family: $font-family-sans-serif)
  font: normal list.slash($size, $line) #{$family}
=fsz($size: $font-size-base)
  font-size: $size
=tac
  text-align: center
=tal
  text-align: left
=tar
  text-align: right
=tdn
  text-decoration: none
=tdu
  text-decoration: underline
=vab
  vertical-align: bottom
=vabl
  vertical-align: baseline
=vatb
  vertical-align: text-bottom
=vam
  vertical-align: middle
=vat
  vertical-align: top
=vatt
  vertical-align: text-top
=h($value: auto, $type: undefined) // $type: undefined or min-height or max-height
  @if $type == max
    max-height: $value
  @else if $type == min
    min-height: $value
  @else
    height: $value
=w($value: auto, $type: undefined) // $type: undefined or min-width or max-width
  @if $type == max
    max-width: $value
  @else if $type == min
    min-width: $value
  @else
    width: $value

// Fade-in/fade-out transition for an element
=fade-icon($icon: ifi-sign-question, $startOpacity: 0, $endOpacity: 1)
  .#{$icon}
    opacity: $startOpacity
    +transition(opacity, 0.5s, ease-out)
  &:hover
    .#{$icon}
      opacity: $endOpacity
// Mixins }}}

// Functions {{{
// Convert PX units to EMs.
  Ex: margin-right: pem(16);
@function pem($pxval, $base: $font-size-base)
  @return #{math.div($pxval, $base)}em
// Functions }}}
