// Rewriting bootstrap button focus outline
@mixin tab-focus() {
  outline: 0;
}

// Breakpoints mixins
@mixin xs {
    @media (max-width: $screen-xs-max) {
        @content;
    }
}
@mixin ms {
    @media (min-width: $screen-ms-min) and (max-width: $screen-ms-max) {
        @content;
    }
}
@mixin sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        @content;
    }
}
@mixin md {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        @content;
    }
}
@mixin lg {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
        @content;
    }
}
@mixin xl {
    @media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
        @content;
    }
}
@mixin xxl {
    @media (min-width: $screen-xxl-min) {
        @content;
    }
}
