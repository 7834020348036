//******************************************************************************
  * Media queries for error pages
  ******************************************************************************
// SM (720-959px)
@include sm
  .inx-error__text-block
    max-width: none
  .lang-label, .inx-error__image-block
    +dn
  .product_list
    li
      min-height: 100px

@include ms
  .inx-error__text-block
    max-width: none
    +fsz(18px)
    padding: 15px
    margin: 0
  .lang-label, .inx-error__image-block
    +dn
  .product_list
    li
      min-height: 100px

@media (max-width: $screen-xs-max)
  .inx-error__text-block
    max-width: none
    +fsz(15px)
    padding: 15px 0
    margin: 0
  .lang-label, .inx-error__image-block
    +dn
  .product_list
    li
      min-height: 100px
