//******************************************************************************
  * Styles for error pages
  * NOTE! Media queries included at the very bottom of this file
  ******************************************************************************
@import "./tb-shared/mixins"
@import "./tb-shared/inx_bootstrap-variables"
@import "bootstrap/scss/functions"
@import "bootstrap/scss/variables"
@import "bootstrap/scss/mixins"
@import "./tb/core_variables"
@import "./tb/core_mixins"

.product_list
  border-top: 2px solid #ececec
  list-style: none
  margin-bottom: 0
  padding-top: 24px
  li
    +h(170px, min)
    padding: 0 3%
    color: $gray
    .ico-product
      color: #556b7c
      margin-left: 0
      padding-left: 0

.inx-b-prod__title
  //+fsz($font-size-h4)
  font-size: 1.1rem

.inx-b-prod__desc
  font-size: 14px

.inx-error
  &__text-block, &__lead
    //+fsz($font-size-h4)
    font-size: 1.1rem


  &__lead
    margin: 28px 0 21px 0

  &__text-block
    margin-bottom: 28px
    padding: 30px 30px 0 30px
    float: left
    max-width: 75%

  &__nav
    margin: 14px 0 21px 0
    padding: 0
    +por
    +tac
    a
      +tdu

  &__nav-item
    display: inline-block
    margin: 7px 0
    padding: 0 21px
    &:last-child, &:only-child
      color: gray
      +db

  &__image-block
    +dib

#sub-header
  margin-left: -5px
  margin-right: -5px
  .nav-login
    +dn
#footer
  +pos

@import "./tb-media-queries/_error-pages"
